/// CONFIG ///

const CONFIG = {
    APP_TITLE: 'Foodsi.ru',
    API_IP: '62.109.20.146',
    API_DOMAIN: 'https://foodsi.ru/',
    API_C_DOMAIN: 'https://foodsi.ru',
    API_GATE: 'https://foodsi.ru/api.php',
    PHONE: '79254343377',
    MINIMAL: 8500,
    CP_PUBLIC_ID: 'sdfsdf',
    INSTAGRAM_URL: 'https://www.instagram.com/sdfsdfdsfsd/',
    PHONE: '+7-925-434-33-77'
};

export default CONFIG;