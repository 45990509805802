import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../SYSTEM/Config.js';


/// REDUX ///
import * as ReduxActions from './../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import LogoFeet from './../../APP/COMPONENTS/HeaderParts/LogoFeet';
import MainNavs from './../../APP/COMPONENTS/HeaderParts/NavBar/MainNavs';
import CatNavs from './../../APP/COMPONENTS/HeaderParts/NavBar/CatNavs';

function mapDispatchToProps(dispatch) {
	return bindActionCreators(ReduxActions, dispatch)
}


class footer extends Component {

	render() {
		return (
			<section id="footer">
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="foot-flex">
								<div>
									<Link to="/" className="logo_block">
										<img src="/system_images/logo.png" id="logo" />
									</Link>
								</div>
								<div className="footer_navs">
									<Link to="/company/delivery" onClick={ () => { this.props.showNavs(false); document.body.classList.remove("modal-open"); } }>
										Ð”Ð¾ÑÑ‚Ð°Ð²ÐºÐ° Ð¸ Ð¾Ð¿Ð»Ð°Ñ‚Ð°
									</Link>
									<Link to="/company/info" onClick={ () => { this.props.showNavs(false); document.body.classList.remove("modal-open"); } }>
										Ðž Ð½Ð°Ñ
									</Link>
									<Link to="/company/partnership" onClick={ () => { this.props.showNavs(false); document.body.classList.remove("modal-open"); } }>
										Ð¡Ð¾Ñ‚Ñ€ÑƒÐ´Ð½Ð¸Ñ‡ÐµÑÑ‚Ð²Ð¾
									</Link>
									<Link to="/company/politika-konfidencialnosti" onClick={ () => { this.props.showNavs(false); document.body.classList.remove("modal-open"); } }>
										ÐŸÐ¾Ð»Ð¸Ñ‚Ð¸ÐºÐ° ÐºÐ¾Ð½Ñ„Ð¸Ð´ÐµÐ½Ñ†Ð¸Ð°Ð»ÑŒÐ½Ð¾ÑÑ‚Ð¸
									</Link>
									<Link to="/company/oferta" onClick={ () => { this.props.showNavs(false); document.body.classList.remove("modal-open"); } }>
										ÐŸÑƒÐ±Ð»Ð¸Ñ‡Ð½Ð° Ð¾Ñ„ÐµÑ€Ñ‚Ð°
									</Link>
									<Link to="/company/contacts" onClick={ () => { this.props.showNavs(false); document.body.classList.remove("modal-open"); } }>
										ÐšÐ¾Ð½Ñ‚Ð°ÐºÑ‚Ñ‹
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<a className="phone_mobile" href={ 'tel: ' + CONFIG.PHONE } target="_blank">
					<img src="/system_images/phone.png" />
				</a>
			</section>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(footer)
