import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import CONFIG from './../../../SYSTEM/Config.js'

/// REDUX ///
import * as ReduxActions from './../../../REDUX/functions.js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

function mapDispatchToProps(dispatch) {
	return bindActionCreators(ReduxActions, dispatch)
}


class Logo extends Component {

	constructor(props) {
		super(props);
	}


	render() {
		return (
			<Link to="/" className="logo_block">
				<img src="/system_images/logo.png" id="logo" />
			</Link>
		);
	}

}

export default connect(
	false,
	mapDispatchToProps
)(Logo)
